.record-Stock {
    position: relative;
    height: 10vh;    
    margin-top: 0px;
    
}

.img-Stock {height: 5vh; width: 5vh;}

#record-container-Stock {
    position: relative;
    height: 30vh;
    overflow-y: hidden;
}

.record_StockCategory {height: 6vh;}

#contentContainer_StockCategory{
    
    height: 30vh;
}










