*:click {outline: none;}
*:focus {outline: none;}
* {overflow-x: hidden;}
* {overflow-y: hidden;}
#main-container_Transactions, #main-container_Shoppinglist{overflow-y: scroll;}
a, a:visited, a:hover, a:active {color: black; text-decoration: none;}
.border {border: solid black thin;}
.centre {text-align: center;}
.left{text-align: left;}
.right {text-align: right;}

.justify-centre {justify-content: center;}
.justify-left {justify-content: left;}
.justify-right {justify-content: right;}


.full{width: 100%;}
.vfull{height: 100%}

.vertical-centre {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}


.left1{margin-left: 1%;}
.left2{margin-left: 2%;}

.right1{margin-right: 1%;}
.right2{margin-right: 2%;}

.top1 {margin-top: 1%;}
.top2 {margin-top: 2%;}


button {
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: white;
  border: none;
  
}


.button-img {font-size: 1.3em;}

.button {cursor: pointer;}

.button-secondary{
   color: darkcyan;
   background-color: rgb(32, 178, 170,0.1);
   }

   .button-secondary:hover{
    color: darkcyan;
    background-color: rgb(32, 178, 170,0.1);
   }

  
  .btn-primary {
    background-color:rgb(32, 178, 170,1);    
    color: white;    
    border-color: white;
  }

  .btn-primary:hover{
    background-color:rgb(32, 178, 170,1);    
    color: white;
    border-color: white
  }

  .btn-primary2 {
    background-color:red;    
    color: white;    
    border-color: white;
  }

  .btn-primary2:hover{
    background-color:red;    
    color: white;
    border-color: white
  }

  
  .button-secondary2{
    color: red;
    background-color: rgb(255, 0, 0,0.1);
    border: solid white thin ;
    }

  .button-secondary2:hover{
    color: red;
    background-color: rgb(255, 0, 0,0.1);
    border:solid white thin ;
  }


.navigateCategory {font-size: 2em;}

.margin-v-auto {margin-top: auto;margin-bottom: auto;}
.margin-h-auto {margin-left: auto; margin-right: auto;}
.record {
        word-break:break-all;
        background-color: rgb(245, 245, 245,0.8);
}



body {height: 100%; position: absolute;width: 100%;}
#root {height: 100%; position: relative; }
.App {position: relative; height: 100%; }
header {height: 13%;}        
.main { height: 85%;  position: relative; background-color: black;}
main { height: 80%; }

footer {
  z-index: 1;
  position: absolute;
  bottom: 2%;
  
  height: 20%; padding: 1%;}


/* .menuItem {border: solid red thin;} */

.headers{background-color: white;}




/* colours */
.orange {color: orange;}
.lightgreen{color: rgb(32, 178, 170,0.8);}
.lightergreen {color: rgb(32, 178, 170,0.5);}
.green {color: lightseagreen;}
.grey{color: rgba(0,0,0,0.5);}
.lightgrey{color: rgba(0,0,0,0.3);}
.black{color: rgba(0,0,0,0.7);}
.green2{color: rgb(0, 128, 0);}
.red{color: rgb(255, 0, 0);}
.background-green {background-color:rgb(32, 178, 170,0.03);}



:disabled {background-color: whitesmoke; color: lightgray;}

.hidden {display: none;}
.invisible{opacity: 0;}

.link {
  padding: 0;
  width: auto;
}



.modals{
  position: absolute;
  width: 100%;
  height: 30%;
  left: 0%;
  top: 0%;
  background-color: rgb(255, 255, 255,1);
  transform: translateY(-10px);
  opacity: 0;
  transition: opacity 0.3s,transform 0.3s;
  display: none;
}

#modalDeleteAccount{
  position: absolute;
  
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;
  background-color: rgb(255, 255, 255,1);
  transform: translateY(-10px);
  opacity: 0;
  transition: opacity 0.3s,transform 0.3s;
  display: none;
}

.modals2{
  position: absolute;
  z-index: 1;
  background-color: rgb(255, 255, 255,1);
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;  
  transform: translateY(-10px);
  opacity: 0;
  transition: opacity 0.3s,transform 0.3s;
  display: none;
}
#modalMessageAccount{
  position: absolute;
  z-index: 3;
  background-color: rgb(255, 255, 255,1);
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;  
  transform: translateY(-10px);
  opacity: 0;
  transition: opacity 0.3s,transform 0.3s;
  display: none;

}
#userModal{
  position: absolute;
  z-index: 1;
  background-color: rgb(255, 255, 255,1);
  
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;  
  transform: translateY(-10px);
  opacity: 0;
  transition: opacity 0.3s,transform 0.3s;
  display: none;
}

#modalAccount {
  position: absolute;
  z-index: 2;
  background-color: rgb(255, 255, 255,1);  
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;  
  transform: translateX(-10px);
  opacity: 0;
  transition: opacity 0.3s,transform 0.3s;
  display: none;
}

#logo {height: 60px;width: auto;}


.form-check-input:checked{
  background-color: rgb(0, 139, 139,1);  
  border: solid white thin;
}

.background-white{
  background-color: white;
}



@media only screen and (max-width: 600px) {
  button {
   height:45px;
   
    
  }

input{height: 50px;}
.menuItem {margin-top: 2%;}

.menuItem small {display: none;}
  
.upDown{ font-size: 3em; }
  
}

