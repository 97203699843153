.negative {color: red;}
.positive {color: green;}


#main-container_Transactions{
    height: 35vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
    overflow-x: hidden;

}

